



















import { Component, Prop } from 'vue-property-decorator';

import Field from './Field.vue';
import { SelectFieldOptionInterface } from '@/shared/lib/interfaces/SelectFieldOptionInterface';

@Component
export default class RadioField extends Field {
  /**
   * Props
   */
  @Prop() private options!: SelectFieldOptionInterface[];
}
