









import {Vue, Component, Prop} from 'vue-property-decorator';
import RadioField from '@/shared/resources/components/forms/fields/RadioField.vue';
import { PaymentsEnum } from '@/modules/payments/enums/PaymentsEnum';
import { PaymentInterface } from '@/modules/payments/interfaces/PaymentsInterface';
import { Validation } from 'vuelidate';

@Component({
  components: {
    RadioField,
  },
})
export default class PaymentsRadioField extends Vue {
  /**
   * Props
   */
  @Prop() private value!: PaymentsEnum;
  @Prop() private payments!: PaymentInterface[];
  @Prop() private validation!: Validation;

  /**
   * Handle payment value
   */
  private get payment(): PaymentsEnum {
    return this.value;
  }

  private set payment(value: PaymentsEnum) {
    this.$emit('input', value);
  }

  /**
   * Getters
   */
  private get options(): any { // TODO add interface
    return this.payments.map((payment: PaymentInterface) => {
      return {
        value: payment.key,
        label: this.$t('payments.' + payment.key + '.name'),
      };
    });
  }
}
